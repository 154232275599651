import React from "react";
import PropTypes from "prop-types";
import { OrderProfileContext } from "./context";
import { makeTrackingUrl } from "utils/misc";
import Header from "components/order-profile/header";
import { PureModal } from "components/ui";
import { formatDate } from "utils/dates";
import UpdateTrackingForm from "./update-tracking-form";
import useRowSelect, {
  SelectRowContext,
  RowSelectCell,
  RowSelectHeaderCell
} from "hooks/useRowSelectNew";

const Tracking = () => {
  const { fetchOrderLineItemsApi, order } =
    React.useContext(OrderProfileContext);

  const canUpdateTracking = [
    "Pending Shipment",
    "Pending Fulfillment",
    "Backordered"
  ].includes(order.order_status);
  // Admins and sass users can update tracking

  const orderLineItems = fetchOrderLineItemsApi.response ?? [];

  const {
    toggleAllRows,
    isRowSelected,
    toggleRow,
    clearSelectedRows,
    selectedRows
  } = useRowSelect(orderLineItems, "line_item_id", {});

  return (
    <div>
      <Header showSpinner={fetchOrderLineItemsApi.isFetching}>
        <div className="bulk-update-tracking-button-container">
          <span>Tracking</span>
          {canUpdateTracking && (
            <PureModal
              renderTrigger={({ openModal }) => (
                <button
                  className="no-print"
                  title={`${selectedRows.length} selected`}
                  onClick={openModal}
                  disabled={selectedRows.length === 0}
                  style={{ marginBottom: 0, padding: "5px 10px" }}
                >
                  Update Tracking
                </button>
              )}
              renderContent={({ closeModal }) => (
                <UpdateTrackingForm
                  seletedLineItems={selectedRows.map(x => x.id)}
                  closeModal={closeModal}
                />
              )}
            />
          )}
        </div>
      </Header>

      <SelectRowContext.Provider
        value={{
          toggleAllRows,
          toggleRow,
          selectedRows,
          isRowSelected,
          clearSelectedRows
        }}
      >
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              {canUpdateTracking && <RowSelectHeaderCell />}
              <th>Product ID</th>
              <th>Quantity</th>
              <th>Ship Date</th>
              <th>Carrier</th>
              <th>Tracking Number</th>
            </tr>
          </thead>
          <tbody>
            {orderLineItems.map(row => (
              <tr key={row.line_item_id}>
                {canUpdateTracking && <RowSelectCell id={row.line_item_id} />}
                <td>{row.manufacturer_id}</td>
                <td>{row.quantity}</td>
                <td>{formatDate(row.tracking?.ship_date, "L")}</td>
                <td>{row.tracking?.carrier}</td>
                <td>
                  <a
                    href={makeTrackingUrl(
                      row.tracking?.carrier,
                      row.tracking?.tracking_number
                    )}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {row.tracking?.tracking_number}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </SelectRowContext.Provider>
    </div>
  );
};

export default Tracking;

Tracking.propTypes = {
  orderId: PropTypes.string.isRequired
};
