import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { formatDate, getAge } from "utils/dates";
import { formatMoney } from "accounting";
import PatientStatus from "./patient-status";
import AccountInformation from "./account_information";
import PatientOutreachDates from "./outreach-dates";
import AccountDetails from "./account_details";
import PatientPhysicians from "./physician";
import OtherDetails from "./other_details";
import AddressInformation from "./address-information";
import PatientPhoneActions from "./phone-actions";
import ProfileMenu from "components/profile/profile-menu";
import HasAppPermission from "components/shared/has-app-permission";
import InsuranceInformation from "./insurance-information/index";

const PatientProfile = ({
  patientInfo,
  onCreateOrder,
  patientId,
  readOnly,
  setDisplayComplianceDataSpinner
}) => {
  React.useEffect(() => {
    if (patientInfo.first_name)
      document.title =
        `${patientInfo.first_name} ${patientInfo.last_name} | ${patientInfo.company_name} ` ||
        "Patient Profile";

    return () => {
      document.title = "S3";
    };
  }, [patientInfo]);

  useEffect(() => {
    setDisplayComplianceDataSpinner(false);
  }, []);

  // Calculate the patient's age based on their dob (date of birth)
  // const getAge = dob => {
  //   return moment().diff(moment(dob), "years");
  // };
  const age = patientInfo.date_of_birth
    ? getAge(patientInfo.date_of_birth)
    : null;

  return (
    <div className="patient-profile">
      <style>{`
        .circle-with-line {
          width: 30px;
          height: 30px;
          background-color: #f0f0f0;
          border-radius: 50%;
          position: relative;
          display: inline-block;
          text-align: center;
          line-height: 26px;
          font-size: 0.8em;
          border: 2px solid red;
        }
        .circle-with-line::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 110%;
          height: 2px;
          background-color: red;
          transform: rotate(135deg);
          transform-origin: center;
          top: 49%;
          left: -4%;
        }
      `}</style>

      {!patientInfo && <h1>loading</h1>}
      <div
        style={{
          marginBottom: 10
        }}
      >
        <PatientStatus patientInfo={patientInfo} />
        <PatientPhoneActions patientInfo={patientInfo} />
        <ProfileMenu
          onCreateOrder={onCreateOrder}
          patientInfo={patientInfo}
          patientId={patientId}
          disabled={readOnly}
        />
        <h2
          style={{
            fontSize: "2em"
          }}
        >
          {patientInfo.name}
          {age !== null && age < 18 && (
            <div
              className="circle-with-line"
              style={{
                display: "inline-block",
                marginLeft: "10px",
                verticalAlign: "middle"
              }}
            >
              18
            </div>
          )}
        </h2>
        {readOnly && (
          <h4
            style={{
              color: "red"
            }}
          >
            Profile is Read-Only
          </h4>
        )}
        <span
          style={{
            fontSize: "1rem",
            fontWeight: "normal",
            lineHeight: "1.6"
          }}
        >
          <div>
            <span style={{ fontWeight: "bold" }}>Acct #: </span>{" "}
            {patientInfo.account_number}
          </div>
          {patientInfo.balance !== null &&
            patientInfo.balance !== undefined && (
              <div
                style={{
                  color: "#222",
                  fontSize: "0.9rem"
                }}
              >
                <span style={{ fontWeight: "bold" }}>Balance: </span>
                {formatMoney(patientInfo.balance)}
              </div>
            )}
          <div
            style={{
              color: "#AAA",
              textTransform: "uppercase"
            }}
          >
            Added: {formatDate(patientInfo.created_at, "L")} - Updated:{" "}
            {formatDate(patientInfo.updated_at, "L")}
          </div>
          {patientInfo.repap_date && (
            <div
              style={{
                color: "#222",
                fontSize: "0.9rem",
                paddingTop: 5
              }}
            >
              <span style={{ fontWeight: "bold", paddingRight: 5 }}>
                Re-Pap Date:
              </span>
              <span>{formatDate(patientInfo.repap_date, "L")}</span>
            </div>
          )}
        </span>
      </div>
      <div>
        <AddressInformation
          patientInfo={patientInfo}
          patientId={patientId}
          readOnly={readOnly}
        />
        <AccountInformation
          patientInfo={patientInfo}
          patientId={patientId}
          readOnly={readOnly}
        />
        <HasAppPermission permission="orderSupplies">
          <PatientOutreachDates
            patientInfo={patientInfo}
            patientId={patientId}
          />
        </HasAppPermission>
        <AccountDetails
          patientInfo={patientInfo}
          patientId={patientId}
          readOnly={readOnly}
        />
        <InsuranceInformation
          patientInfo={patientInfo}
          patientId={patientId}
          readOnly={readOnly}
        />
        <PatientPhysicians
          patientId={patientId}
          patientName={patientInfo.name}
          physician={patientInfo.physician}
          readOnly={readOnly}
        />
        <OtherDetails patientId={patientId} readOnly={readOnly} />
      </div>
    </div>
  );
};

PatientProfile.propTypes = {
  patientInfo: PropTypes.object.isRequired,
  patientId: PropTypes.string.isRequired,
  onCreateOrder: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  setDisplayComplianceDataSpinner: PropTypes.func.isRequired
};

PatientProfile.defaultProps = {
  readOnly: false
};

export default PatientProfile;
