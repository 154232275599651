import React from "react";
import { FieldArray, useFormikContext } from "formik";

const ProductLinesForm = () => {
  const { values } = useFormikContext();

  const [selectedProductLine, setSelectedProductLine] = React.useState();

  const options = ["CPAP", "CGM"];
  const avaliableProductLines = options.filter(
    x => values.patient_product_lines.indexOf(x) === -1
  );

  const multipleOptions =
    options.length > 1 || values.patient_product_lines.length == 0;

  return (
    <fieldset>
      <legend>Product Line</legend>
      <FieldArray
        name="patient_product_lines"
        render={arrayHelpers => (
          <div>
            {values.patient_product_lines?.map((x, index) => (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  fontSize: "1.5em",
                    margin: 10,
                  justifyContent: "space-between"
                }}
                key={index}
              >
                <div style={{ width: 50 }}>{x}</div>
                {multipleOptions && (
                  <button
                    type="button"
                    onClick={() => arrayHelpers.remove(index)}
                    style={{ margin: 0, padding: "5px" }}
                  >
                    Remove
                  </button>
                )}
              </div>
            ))}
            {multipleOptions && (
              <div
                style={{
                  display: "flex",
                  flex: 1
                }}
              >
                <select
                  value={selectedProductLine}
                  onChange={e => setSelectedProductLine(e.target.value)}
                  style={{ margin: 0, padding: "5px" }}
                >
                  <option />
                  {avaliableProductLines.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <button
                  type="button"
                  onClick={() =>
                    arrayHelpers.insert(
                      values.patient_product_lines.length,
                      selectedProductLine
                    )
                  }
                  style={{ margin: 0, padding: "5px" }}
                  disabled={!selectedProductLine}
                >
                  Add
                </button>
              </div>
            )}
          </div>
        )}
      />
    </fieldset>
  );
};

export default ProductLinesForm;
