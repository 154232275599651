import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";
import InventoryTableRow from "./inventory-table-row";

const filterInventoryItem = (searchValue, searchBy) => inventoryItem => {
  if (searchValue.length > 0) {
    switch (searchBy) {
      case "product_name":
        return inventoryItem.name
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      case "product_id":
        return inventoryItem.product_id
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      case "hcpcs":
        return inventoryItem.hcpcs
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      default:
        return false;
    }
  }
  return true;
};

const InventoryTable = ({
  equipmentTypeOptions,
  manufacturerOptions,
  inventoryItems,
  searchValue,
  searchBy,
  containerClass,
  rowComponent,
  selectedProductLine,
  onRowChange
}) => {
  const { rows, query, queryDispatcher } = useLocalQuery(inventoryItems, {
    sort: { by: "name", direction: "asc" },
    filters: {}
  });
  const [results, setResults] = useState(rows);
  const getFilteredInventoryRows = async (selectedProductLine) => {
    var results = rows
      .filter(filterInventoryItem(searchValue, searchBy))
      .filter(x => x.product_line == selectedProductLine);
    setResults(results);
  };
  useEffect(() => {
    getFilteredInventoryRows(selectedProductLine);
  }, [rows, selectedProductLine]);
  useEffect(() => {
    getFilteredInventoryRows();
  }, [searchValue]);
  return (
    <Table
      TableRow={rowComponent || InventoryTableRow}
      onRowChange={onRowChange}
      containerClass={"query-table inventory-table " + containerClass}
      columns={[
        {
          header: "Name",
          field: "name",
          style: { width: "500px" }
        },
        {
          header: "Product Id",
          field: "product_id"
        },
        {
          header: "HCPCS",
          field: "hcpcs"
        },
        {
          header: "Equipment Type",
          field: "product_type",
          filterKey: "product_type",
          filters: equipmentTypeOptions
        },
        {
          header: "Manufacturer",
          field: "manufacturer",
          filterKey: "manufacturer_id",
          filters: manufacturerOptions
        },
        {
          header: "Avg Reimbursment",
          field: "avgReimbursment"
        },
        /*
        {
          header: "Product line",
          field: "product_line",
          filters: [
            {
              value: "CPAP",
              text: "CPAP"
            },
            {
              value: "CGM",
              text: "CGM"
            }
          ]
        },
        */
        {
          header: "Active",
          field: "productActive",
          filters: [
            {
              value: true,
              text: "Yes"
            },
            {
              value: false,
              text: "No"
            }
          ]
        }
      ]}
      query={query}
      queryDispatcher={queryDispatcher}
      rows={results}
      rowKey="id"
    />
  );
};

export default connect(state => ({
  manufacturerOptions: selectors.getManufacturerOptions(state),
  equipmentTypeOptions: selectors.getEquipmentTypeOptions(state)
}))(InventoryTable);

InventoryTable.propTypes = {
  equipmentTypeOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  manufacturerOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  inventoryItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchValue: PropTypes.string,
  searchBy: PropTypes.string,
  containerClass: PropTypes.string,
  rowComponent: PropTypes.elementType,
  onRowChange: PropTypes.func
};

InventoryTable.defaultProps = {
  containerClass: ""
};
