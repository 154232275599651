import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { updatePatient } from "actions/patient";
import { Tabs, Tab, TabList, TabPanel } from "react-tabs";
import ScheduledDates from "./scheduled_dates";

const PatientOutreachDates = ({
  patientInfo,
  updatePatient,
  patientId,
  readOnly
}) => {
  return (
    <div>
      <div className="patient-schedule-dates-container">
        <Tabs direction={"rtl"}>
          <TabList style={{ display: "flex", flexDirection: "row", flex: 1 }}>
            <h3
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                fontSize: "1.4em",
                textTransform: "uppercase"
              }}
            >
              Scheduled Dates
            </h3>
            <Tab>CPAP</Tab>
            <Tab>CGM</Tab>
          </TabList>
          <TabPanel>
            <ScheduledDates
              patientId={patientId}
              updatePatient={updatePatient}
              outreach_date={patientInfo.outreach_date}
              outreach_cycle_count={patientInfo.outreach_cycle_count}
              patientInfo={patientInfo}
              readOnly={readOnly}
            />
          </TabPanel>

          <TabPanel>
            <ScheduledDates
              patientId={patientId}
              updatePatient={updatePatient}
              outreach_date={null}
              outreach_cycle_count={1}
              patientInfo={patientInfo}
              readOnly={readOnly}
            />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

PatientOutreachDates.propTypes = {
  patientId: PropTypes.string.isRequired,
  patientInfo: PropTypes.object.isRequired,
  updatePatient: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired
};

PatientOutreachDates.defaultProps = {
  readOnly: false
};

export default connect(
  (state, { patientId }) => ({
    patientInfo: selectors.getPatientInfo(state, patientId)
  }),
  { updatePatient }
)(PatientOutreachDates);
