import React from "react";
import PropTypes from "prop-types";

export const QuantityInput = ({
  line_item_id,
  quantity,
  updateOrderLineItemByIdApi,
  canChangeQuantities
}) => {
  const [value, setValue] = React.useState(quantity || 0);

  React.useEffect(() => {
    setValue(quantity);
  }, [quantity]);

  const handleChange = ({ target: { value } }) => {
    if (!value || value > 0) setValue(value);
  };

  const handleBlur = () => {
    if (value !== quantity) {
      if (value > 9) {
        if (
          window.confirm(
            `Are you sure you want to set quantity to ${value} for this item?`
          )
        )
          updateOrderLineItemByIdApi.fetchData({
            line_item_id,
            quantity: value
          });
        else {
          setValue(quantity);
        }
      } else
        updateOrderLineItemByIdApi.fetchData({
          line_item_id,
          quantity: value
        });
    }
  };

  return (
    <input
      className="quantity-input"
      type="number"
      min="1"
      disabled={updateOrderLineItemByIdApi.isFetching || !canChangeQuantities}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

QuantityInput.propTypes = {
  line_item_id: PropTypes.string.isRequired,
  quantity: PropTypes.number,
  updateOrderLineItemByIdApi: PropTypes.object.isRequired,
  canChangeQuantities: PropTypes.bool
};

export const ZeroBilledInput = ({
  value,
  line_item_id,
  updateOrderLineItemByIdApi,
  canChangeQuantities = false
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1
      }}
    >
      <input
        type="checkbox"
        checked={!!value}
        disabled={updateOrderLineItemByIdApi.isFetching || !canChangeQuantities}
        onChange={async ({ target }) => {
          await updateOrderLineItemByIdApi.fetchData({
            line_item_id,
            zeroChargeItem: target.checked
          });
        }}
      />
    </div>
  );
};

ZeroBilledInput.propTypes = {
  line_item_id: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  updateOrderLineItemByIdApi: PropTypes.object.isRequired,
  value: PropTypes.bool,
  canChangeQuantities: PropTypes.bool
};

export const DropshipInput = ({
  value,
  line_item_id,
  updateOrderLineItemByIdApi,
  canChangeQuantities = false
}) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flex: 1
    }}
  >
    <input
      type="checkbox"
      checked={!!value}
      disabled={canChangeQuantities}
      onChange={async ({ target }) => {
        await updateOrderLineItemByIdApi.fetchData({
          line_item_id,
          dropship_item: target.checked
        });
      }}
    />
  </div>
);

DropshipInput.propTypes = {
  line_item_id: PropTypes.string.isRequired,
  updateOrderLineItemByIdApi: PropTypes.object.isRequired,
  canChangeQuantities: PropTypes.bool,
  orderId: PropTypes.string.isRequired,
  requestUpdateLineItemById: PropTypes.func.isRequired,
  value: PropTypes.bool
};

export const ClaimNoteInput = ({
  value,
  line_item_id,
  updateOrderLineItemByIdApi,
  canChangeQuantities = false
}) => {
  const [error, setError] = React.useState();
  const [noteLength, setNoteLength] = React.useState(value?.length ?? 0);
  const [noteValue, setNoteValue] = React.useState(value);

  const handleChange = ({ target }) => {
    if (target?.value?.length > 80) {
      setError("Note cannot be longer than 80 characters");
    } else {
      setError("");
    }
    setNoteValue(target?.value);
    setNoteLength(target?.value?.length);
  };

  const submitData = async () => {
    await updateOrderLineItemByIdApi.fetchData({
      line_item_id,
      claim_order_note: noteValue
    });
  }

  const handleBlur = () => {
    if ((noteValue?.length <= 80) && (value !== noteValue)) submitData();
  };

  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span className="note-validation-error">{error}</span>
      <textarea
        disabled={canChangeQuantities}
        value={noteValue}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <span>{noteLength}/80</span>
    </div>
)};

ClaimNoteInput.propTypes = {
  line_item_id: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  value: PropTypes.string,
  updateOrderLineItemByIdApi: PropTypes.object.isRequired,
  canChangeQuantities: PropTypes.bool
};
