import React from "react";
import PropTypes from "prop-types";
import { formatPhone, formatZip } from "utils/misc";
import PatientPhysicianForm from "components/physician-search/patient-physician-form";
import PureModal from "components/ui/modal/pure-modal";
import MdEdit from "react-icons/lib/md/edit";
import IconButton from "components/ui/icon-button";
import { Ul, AttributeItem } from "components/lists";

const PhysicianInformation = ({
  patientId,
  physician,
  updatePatient,
  readOnly
}) => (
  <Ul>
    <AttributeItem title="Name">{physician?.name}</AttributeItem>
    <AttributeItem title="Street Address">
      {physician?.address?.street_address}
    </AttributeItem>
    <AttributeItem title="City">{physician?.address?.city}</AttributeItem>
    <AttributeItem title="State">{physician?.address?.state}</AttributeItem>
    <AttributeItem title="ZIP">
      {formatZip(physician?.address?.zip)}
    </AttributeItem>
    <AttributeItem title="Phone">{formatPhone(physician?.phone)}</AttributeItem>
    <AttributeItem title="Fax">{formatPhone(physician?.fax)}</AttributeItem>
    <AttributeItem title="NPI #">{physician?.npi}</AttributeItem>
    <div
      style={{
        display: "flex",
        flex: 1,
        alignItems: "center",
        marginTop: 10,
        width: "100%"
      }}
    >
      <PureModal
        renderTrigger={({ openModal }) => (
          <IconButton
            icon={<MdEdit />}
            text="Edit"
            onClick={openModal}
            style={{ fontSize: "16px" }}
            disabled={readOnly}
          />
        )}
        renderContent={({ closeModal }) => (
          <PatientPhysicianForm
            patientId={patientId}
            physician={physician}
            updatePatientPhysician={async physician => {
              await updatePatient({
                patientId,
                updates: {
                  physician
                }
              });
              closeModal();
            }}
          />
        )}
      />
    </div>
  </Ul>
);

PhysicianInformation.propTypes = {
  physician: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.shape({
      street_address: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired
    }).isRequired,
    phone: PropTypes.string.isRequired,
    fax: PropTypes.string,
    npi: PropTypes.string
  }),
  patientId: PropTypes.string.isRequired,
  updatePatient: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired
};
PhysicianInformation.defaultProps = {
  physician: {
    name: "",
    address: {
      street_address: "",
      city: "",
      state: "",
      zip: ""
    },
    phone: "",
    fax: "",
    npi: ""
  }
};

export default PhysicianInformation;
