import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "utils/dates";
import { Ul, AttributeItem } from "components/lists";
import DayPicker from "react-day-picker";
import CalenderControls from "components/ui/date-picker/calender-controls";
import HasRolePermission from "components/shared/has-role-permission";

const ScheduledDates = ({
  patientInfo,
  outreach_date,
  outreach_cycle_count,
  updatePatient,
  patientId,
  readOnly
}) => {
  const [calenderIsOpen, setCalenderIsOpen] = React.useState();

  return (
    <Ul>
      <AttributeItem title="Outreach Date">
        {formatDate(outreach_date, "MM/DD/YYYY")}
      </AttributeItem>
      <AttributeItem title="Outreach Cycle">
        {outreach_cycle_count}
      </AttributeItem>
      <AttributeItem title="Override Outreach Date:">
        <div className="overide-date-checkbox-container">
          <input
            type="checkbox"
            checked={patientInfo.overrideEligibilityDate || false}
            disabled={readOnly || !patientInfo.active}
            onChange={({ target: { checked } }) => {
              updatePatient({
                patientId,
                updates: {
                  overrideEligibilityDate: checked
                }
              });
            }}
          />
        </div>
      </AttributeItem>

      {!patientInfo.overrideEligibilityDate && (
        <React.Fragment>
          <div className="patient-outreach-date-controls">
            <HasRolePermission
              allowedRoles={[
                "Administrator",
                "SleepCoach",
                "ServiceCoach",
                "ServiceAdmin",
                "ContractorAdministrator"
              ]}
            >
              <button
                className="button secondary icon-button"
                disabled={readOnly || !patientInfo.active}
                onClick={() => {
                  updatePatient({
                    patientId,
                    updates: {
                      outreach_date: null,
                      update_forms: false
                    }
                  });
                }}
              >
                Push Outreach
              </button>
            </HasRolePermission>
            <button
              className="button secondary icon-button"
              disabled={readOnly || !patientInfo.active}
              onClick={() => setCalenderIsOpen(true)}
            >
              Push Outreach Manual
            </button>
          </div>
          <div className="date-picker container">
            {calenderIsOpen && (
              <div id={"datePicker"} className="entryContainer">
                <div
                  className="outOfBounds"
                  onClick={() => setCalenderIsOpen(false)}
                />
                <div className="pickerContainer">
                  <DayPicker
                    onDayClick={day => {
                      if (!day) return;
                      const date = formatDate(day, "yyyy-MM-DD");
                      setCalenderIsOpen(false);

                      updatePatient({
                        patientId,
                        updates: {
                          outreach_date: date,
                          update_forms: false
                        }
                      });
                    }}
                    containerProps={{ style: { padding: 0 } }}
                    selectedDay={outreach_date}
                  />
                  <CalenderControls
                    handleDayChange={day => {
                      if (!day) return;
                      const date = formatDate(day, "yyyy-MM-DD");
                      setCalenderIsOpen(false);

                      updatePatient({
                        patientId,
                        updates: {
                          outreach_date: date,
                          update_forms: false
                        }
                      });
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </Ul>
  );
};

ScheduledDates.propTypes = {
  patientId: PropTypes.string.isRequired,
  patientInfo: PropTypes.object.isRequired,
  outreach_date: PropTypes.string.isRequired,
  outreach_cycle_count: PropTypes.number.isRequired,
  updatePatient: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired
};

ScheduledDates.defaultProps = {
  readOnly: false
};

export default ScheduledDates;
