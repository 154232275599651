import React, { createContext } from "react";
import PropTypes from "prop-types";
import { get, put, post } from "utils/api";
import useFetch from "hooks/useFetch";
import useTasks from "features/tasks/use-tasks";

export const OrderProfileContext = createContext();

const OrderProfileNewProvider = ({ orderId, children }) => {
  const [id, setId] = React.useState(orderId);

  const fetchOrderProfileApi = useFetch({
    apiFn: orderId => get(`order-profile/${orderId}`),
    defaultValue: {}
  });

  const updateOrderProfileApi = useFetch({
    apiFn: values => put(`orders/${orderId}`, values),
    defaultValue: {},
    onSuccess: () => fetchOrderProfileApi.fetchData(orderId)
  });

  const setOrderProfileAccessApi = useFetch({
    apiFn: orderId => post(`orders/${orderId}/access`, {})
  });

  const fetchOrderLineItemsApi = useFetch({
    apiFn: orderId => get(`orders/${orderId}/line-items`),
    defaultValue: []
  });

  const createOrderNoteApi = useFetch({
    apiFn: values => post(`orders/${orderId}/notes`, values),
    defaultValue: {},
    onSuccess: () => fetchOrderNotesApi.fetchData(orderId)
  });

  const fetchOrderNotesApi = useFetch({
    apiFn: orderId => get(`orders/${orderId}/notes`),
    defaultValue: [],
    transformResponse: response => response?.notes ?? []
  });

  const fetchOrderEquipmentApi = useFetch({
    apiFn: orderId => get(`orders/${orderId}/equipment_requested`),
    defaultValue: {
      compatibleItems: {
        patientCompatibleItems: [],
        orderCompatibleItems: []
      },
      equipment_requested: []
    }
  });

  const addOrderLineItemByIdApi = useFetch({
    apiFn: ({ product_id, addQuantity }) =>
      post(`orders/${orderId}/add_line_item_by_id`, {
        product_id,
        addQuantity
      }),
    onSuccess: () => {
      fetchOrderEquipmentApi.fetchData(orderId);
      fetchOrderLineItemsApi.fetchData(orderId);
    }
  });

  const addOrderLineItemApi = useFetch({
    apiFn: ({ id, addQuantity }) =>
      post(`orders/${orderId}/add_line_item`, {
        id,
        addQuantity
      }),
    onSuccess: () => {
      fetchOrderEquipmentApi.fetchData(orderId);
      fetchOrderLineItemsApi.fetchData(orderId);
    }
  });

  const updateTrackingApi = useFetch({
    apiFn: payload => post(`orders/${orderId}/add_shipping_details`, payload),
    onSuccess: () => {
      fetchOrderLineItemsApi.fetchData(orderId);
      fetchOrderProfileApi.fetchData(orderId);
    }
  });

  // JANKY hack to prevent re-fetching when orderId reference changes
  React.useEffect(() => {
    if (orderId != id) {
      setId(orderId);
    }
  }, [orderId]);

  React.useEffect(() => {
    fetchOrderProfileApi.fetchData(orderId);
    fetchOrderLineItemsApi.fetchData(orderId);
    fetchOrderNotesApi.fetchData(orderId);
    setOrderProfileAccessApi.fetchData(orderId);
    fetchOrderEquipmentApi.fetchData(orderId);
  }, [id]);

  // JANKY hack to prevent re-fetching when orderId reference changes
  const [patientId, setPatientId] = React.useState(
    fetchOrderProfileApi.response?.patient_id
  );
  React.useEffect(() => {
    if (patientId != fetchOrderProfileApi.response?.patient_id) {
      setPatientId(fetchOrderProfileApi.response?.patient_id);
    }
  }, [fetchOrderProfileApi.response?.patient_id]);

  const {
    tasks,
    createTask,
    createTaskNote,
    getPatientTasks,
    updateTask,
    isFetchingTasks
  } = useTasks(patientId);

  const patientFormAttributesAPI = useFetch({
    apiFn: patientId => get(`patients/${patientId}/form-attributes`),
    defaultValue: {
      company_insurances: [],
      accessible_users: { dmes: [], s3_users: [] }
    }
  });

  React.useEffect(() => {
    if (patientId) {
      patientFormAttributesAPI.fetchData(patientId);
      getPatientTasks(patientId);
    }
  }, [patientId]);

  const order = fetchOrderProfileApi?.response;
  const isFetchingOrder = fetchOrderProfileApi?.isFetching;
  return (
    <OrderProfileContext.Provider
      value={{
        order,
        fetchOrderProfile: fetchOrderProfileApi.fetchData,
        isFetchingOrder,
        tasks,
        createTask,
        createTaskNote,
        getPatientTasks,
        updateTask,
        isFetchingTasks,
        updateOrder: updateOrderProfileApi.fetchData,
        isUpdatingOrder: updateOrderProfileApi.isFetching,
        fetchOrderLineItemsApi,
        createOrderNoteApi,
        fetchOrderNotesApi,
        updateTrackingApi,
        addOrderLineItemByIdApi,
        addOrderLineItemApi,
        fetchOrderEquipmentApi
      }}
    >
      {children}
    </OrderProfileContext.Provider>
  );
};

OrderProfileNewProvider.propTypes = {
  orderId: PropTypes.string,
  children: PropTypes.node
};

export default OrderProfileNewProvider;
