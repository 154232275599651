import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "utils/dates";

function SelectOrderProductLineModal({
  onCreateOrder,
  patient_product_lines,
  closeSelf
}) {
  const modalRef = React.useRef(null);
  React.useEffect(() => {
    // Close profile menu when this modal is opened
    modalRef.current?.click();
  }, [modalRef]);
  return (
    <div
      style={{
        padding: 10,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
      ref={modalRef}
    >
      <h3>Select Product Line</h3>
      <p style={{ fontSize: 16 }}>
        Choose the product line you would like to order for this patient.
      </p>
      {patient_product_lines.map(x => (
        <div
          key={x.text}
          style={{
            display: "grid",
            gridTemplateColumns: "200px 300px",
              marginBottom: 10,
            alignItems: "center",
            gridGap: 20
          }}
        >
          <div>
            <button
              style={{ margin: 0 }}
              onClick={() => {
                onCreateOrder(x.text);
                closeSelf();
              }}
            >
              Order {x.text} Supplies
            </button>
          </div>
          <div style={{ fontSize: 18 }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              Elgibility Date:
              <span style={{ color: "green", paddingLeft: 10 }}>
                {formatDate(x.eligiblity_date)}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
SelectOrderProductLineModal.propTypes = {
  onCreateOrder: PropTypes.func.isRequired,
  closeSelf: PropTypes.func.isRequired,
  patient_product_lines: PropTypes.array.isRequired,
  patientInfo: PropTypes.object.isRequired
};

export default SelectOrderProductLineModal;
