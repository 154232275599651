import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updatePatient } from "actions/patient";
import { Tabs, Tab, TabList, TabPanel } from "react-tabs";
import PhysicianInformation from "./physician-information";

const PatientPhysicians = ({
  patientId,
  physician,
  updatePatient,
  readOnly
}) => (
  <>
    <div className="patient-physician-container">
      <Tabs direction={"rtl"}>
        <TabList style={{ display: "flex", flexDirection: "row", flex: 1 }}>
          <h3
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              fontSize: "1.4em",
              textTransform: "uppercase"
            }}
          >
            Physician
          </h3>
          <Tab>CPAP</Tab>
          <Tab>CGM</Tab>
        </TabList>
        <TabPanel>
          <PhysicianInformation
            physician={physician}
            updatePatient={updatePatient}
            readOnly={readOnly}
            patientId={patientId}
          />
        </TabPanel>
        <TabPanel>
          <PhysicianInformation
            physician={{}}
            updatePatient={updatePatient}
            readOnly={readOnly}
            patientId={patientId}
          />
        </TabPanel>
      </Tabs>
    </div>
  </>
);

PatientPhysicians.propTypes = {
  physician: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.shape({
      street_address: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired
    }).isRequired,
    phone: PropTypes.string.isRequired,
    fax: PropTypes.string,
    npi: PropTypes.string
  }),
  patientId: PropTypes.string.isRequired,
  updatePatient: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired
};

PatientPhysicians.defaultProps = {
  physician: {
    name: "",
    address: {
      street_address: "",
      city: "",
      state: "",
      zip: ""
    },
    phone: "",
    fax: "",
    npi: ""
  }
};

export default connect(null, { updatePatient })(PatientPhysicians);
