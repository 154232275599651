import React from "react";
import { OrderProfileContext } from "./context";

const insuranceChangeRegEx =
  /(The\spatient's\sprimary\sinsurance\scompany).*?(?=\s+(This patient requested).*)/gs;

const getInsuranceChangeFromNotes = notes => {
  try {
    var orderNote = notes[0] ?? "";
    const matches = orderNote?.note.match(insuranceChangeRegEx);
    return matches[0];
  } catch (err) {
    return null;
  }
};
const InsuranceInformation = () => {

  const { fetchOrderNotesApi } = React.useContext(OrderProfileContext);

  const orderNote = fetchOrderNotesApi.response?.[0] ?? "";

  console.log(orderNote)

  return (
    <div style={{ margin: 10 }}>{getInsuranceChangeFromNotes(orderNote)}</div>
  );
};

export default InsuranceInformation;
