import moment from "moment";
import "moment-timezone";
import zipToTz from "zipcode-to-timezone";
import { isNil } from "ramda";
import { host } from "environment";

let date = new Date();
let y = date.getFullYear();
let m = date.getMonth();

export const firstDayOfLastMonth = () => {
  let startDate = new Date(y, m - 1, 1);
  return moment(startDate).format("MM/DD/YYYY");
};
export const lastDayOfLastMonth = () => {
  let endDate = new Date(y, m, 0);
  return moment(endDate).format("MM/DD/YYYY");
};

export const firstDayOfThisMonth = () =>
  moment().startOf("month").format("MM/DD/YYYY");
export const lastDayOfThisMonth = () =>
  moment().endOf("month").format("MM/DD/YYYY");
export const today = () => moment(new Date()).format("MM/DD/YYYY");
export const getAge = date => moment().diff(date, "years");

export const getLocalTimeFromZip = (zip = "") => {
  const timeZone = zipToTz.lookup(zip?.trim().slice(0, 5) ?? "");
  return isNil(timeZone)
    ? "N/A"
    : moment.tz(moment(), timeZone).format("hh:mm A z");
};
export const getTimezoneFromZip = zip => {
  return zipToTz.lookup(zip.trim().slice(0, 5));
};

export const getTimeEllapsedInSeconds = date => {
  if (!date) return 0;
  const diff = moment.duration(moment.tz(date, "US/Central").diff(moment()));
  return Math.ceil(Math.abs(diff.asSeconds()));
};

export const formatSeconds = (seconds = 0) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = seconds % 60;
  return [h, m > 9 ? m : h ? "0" + m : m || "0", s > 9 ? s : "0" + s]
    .filter(a => a)
    .join(":");
};

export const enumerateYearsBetweenDates = function (startDate, endDate) {
  let momentEndDate = moment(endDate, "MM-DD-YYYY");
  let tempDate = moment(startDate, "MM-DD-YYYY");
  let dates = [];

  while (moment(tempDate).isSameOrBefore(momentEndDate)) {
    dates.push(tempDate.format("YYYY"));
    tempDate.add(1, "years");
  }
  return dates;
};

export const monthDates = moment.months().map((monthName, i) => {
  const monthNumber = i < 9 ? `0${i + 1}` : `${i + 1}`;
  return { value: monthNumber, displayName: monthName };
});

export const formatTimeFromHour = time => {
  if (typeof time === "number") {
    return time === 23
      ? "12 AM"
      : time < 11
        ? `${time + 1} AM`
        : `${(time % 12) + 1} PM`;
  }
  return time;
};

export const getCurrentTimeZone = () => moment.tz.guess(true);

export const isBeforeNow = date =>
  moment.tz(date, "US/Central").isBefore(moment());

export const formatDate = (date, format = "L") =>
  date ? moment(date).format(format) : "";

export const convertTimeSpan = time => {
  if (time.endsWith(":00")) time = time.substring(0, time.length - 3);
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
};

export const updateTimeZone = async (token, currentTimeZone) => {
  try {
    const response = await fetch(`${host}users/update_timezone`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json"
      },
      body: JSON.stringify(currentTimeZone)
    });
    if(response.ok){
      return await response.json();
    }
  } catch (error) {
    console.error("API ERROR: ", error);
  }
};