import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { useLocation } from "react-router-dom";
import {
  fetchPatient,
  // postPatientAccess,
  fetchPatientProfileInfo,
  fetchPatientNotes,
  invalidatePatient,
  invalidatePatientProfileInfo
} from "actions/patient";
import { InlineWaiting, withModal } from "components/ui";
import Profile from "components/profile";
import PatientAlerts from "components/profile/patient-alerts";
import PatientTabs from "components/profile/tabs";
import Composer from "components/email/composer/index";
import NewNote from "components/profile/new-note";
import OrderForm from "components/order-form-new";
import OrderProfile from "components/order-profile-new";
import PatientNotes from "./patient-notes";
import PatientVerification from "./patient-verification";
import useBonafide, {
  BonafidePatientContext
} from "features/bonafide/use-bonafide";
import { selectors } from "reducers";
import { clone } from "ramda";
import {
  PatientCompanyActiveEquipmentContext,
  usePatientCompanyActiveEquipment
} from "components/forms/add-equipment";
import { AppPermissionProvider } from "components/shared/app-permission";
import PatientProfileProvider from "components/profile/context";

const PatientProfile = ({
  patientId,
  fetchPatient,
  invalidatePatient,
  infoLastUpdated,
  fetchWaitDuration,
  openModal,
  patientInfo,
  fetchingInfo,
  fetchingNotes,
  user,
  verify,
  patientVerification
}) => {
  React.useEffect(() => {
    fetchIfNecessary();
    return () => {
      //invalidatePatient({ patientId });
    };
  }, [patientId]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectedTab = searchParams.get("selectedTab");
  const isViewingDashboard = searchParams.get("fromDashboard");

  const { isFetchingCompanyActiveEq, active_products } =
    usePatientCompanyActiveEquipment({ patientId });
  const {
    bonafidePatientInfo,
    updatePatientInfo,
    bonafideAddHoldReasons,
    errors,
    isFetching
  } = useBonafide({
    patientId,
    uses_bonafide_api: patientInfo && patientInfo.uses_bonafide_api
  });
  const [orderFormSelectedProduct, setOrderFormProductType] =
    React.useState("");
  const [displayComplianceDataSpinner, setDisplayComplianceDataSpinner] =
    useState(false);
  const [isCompliant, setIsCompliant] = useState();
  const [reloadComplianceTab, setReloadComplianceTab] = useState(false);
  const [date, setDate] = useState(null);

  useEffect(() => {
    if (date == null) setDate(patientInfo.setup_date);
    else if (date != patientInfo.setup_date) {
      setReloadComplianceTab(true);
      setDate(patientInfo.setup_date);
    } else setReloadComplianceTab(false);
  }, [patientInfo, date]);

  useEffect(() => {
    if (isCompliant !== undefined || isCompliant != null)
      patientInfo.compliance_met = isCompliant;
  }, [isCompliant]);

  function handleCreateOrderSuccess({ id }) {
    setOrderFormProductType("");
    openModal(<OrderProfile orderId={id} />, { large: true });
  }

  function fetchIfNecessary() {
    const now = Date.now();
    if (infoLastUpdated && now - infoLastUpdated > fetchWaitDuration) {
      invalidatePatient({ patientId });
    }
    fetchPatient({ patientId });
    setOrderFormProductType("");
  }

  let verified = true;
  let readOnly = false;
  if (
    patientInfo.id &&
    (verify || patientVerification.patientId === patientInfo.id)
  ) {
    verified = patientVerification.verified;
    readOnly = !verified && patientVerification.readOnly;
  }

  // Prevent patient profile from showing Date of birth and address
  //   so we can use those fields to verify the identity of the patient
  let patientProfileInfo = clone(patientInfo);
  if (!verified) {
    patientProfileInfo.date_of_birth = "";
    patientProfileInfo.address = {};
  }

  return (
    <AppPermissionProvider patientId={patientId}>
      <>
        {!verified && !readOnly && patientInfo && (
          <PatientVerification patientInfo={patientInfo} />
        )}
        <PatientProfileProvider patientId={patientId} patientInfo={patientInfo}>
          <div
            style={{
              display: "flex",
              minHeight: "100%",
              padding: "20px"
            }}
            className="patient-profile"
          >
            <div
              style={{
                flex: 3,
                marginRight: 15,
                minWidth: 400,
                maxWidth: 350,
                paddingRight: 10,
                borderRight: "1px solid #DDD"
              }}
            >
              {fetchingInfo ? (
                <InlineWaiting reason="Fetching patient profile..." />
              ) : (
                patientProfileInfo && (
                  <Profile
                    patientId={patientId}
                    patientInfo={patientProfileInfo}
                    onCreateOrder={product_line => {
                      setOrderFormProductType(product_line);
                    }}
                    readOnly={readOnly}
                    setDisplayComplianceDataSpinner={value =>
                      setDisplayComplianceDataSpinner(value)
                    }
                  />
                )
              )}
            </div>
            <BonafidePatientContext.Provider
              value={{
                ...bonafidePatientInfo,
                errors,
                isFetching,
                updatePatientInfo,
                bonafideAddHoldReasons
              }}
            >
              <div
                style={{
                  flex: 9,
                  height: "100%"
                }}
              >
                {patientInfo && (
                  <PatientAlerts
                    patientInfo={patientInfo}
                    patientId={patientId}
                    isCompliant={isCompliant}
                  />
                )}
                {orderFormSelectedProduct && (
                  <OrderForm
                    patientId={patientId}
                    handleCreateOrderSuccess={handleCreateOrderSuccess}
                    productLine={orderFormSelectedProduct}
                    handleOrderFormX={() => setOrderFormProductType("")}
                  />
                )}
                <PatientCompanyActiveEquipmentContext.Provider
                  value={{
                    isFetchingCompanyActiveEq,
                    active_products
                  }}
                >
                  <PatientTabs
                    patientId={patientId}
                    patientInfo={patientInfo}
                    defaultSelectedTab={selectedTab}
                    displayComplianceDataSpinner={displayComplianceDataSpinner}
                    isViewingDashboard={isViewingDashboard}
                    setIsCompliant={value => setIsCompliant(value)}
                    reloadComplianceTab={reloadComplianceTab}
                  />
                </PatientCompanyActiveEquipmentContext.Provider>
                {user && patientInfo && patientInfo.account_number && (
                  <Composer
                    companyName={patientInfo?.company_name}
                    patientFirstName={patientInfo?.first_name}
                    patientLastName={patientInfo?.last_name}
                    patientName={patientInfo?.name}
                    patientId={patientId}
                    patientEmail={patientInfo?.email}
                    patientAccountNumber={patientInfo?.account_number}
                    branchOfficeName={patientInfo?.branch_office_name}
                    callBackNumber={patientInfo?.company_outbound_phone_number}
                    patientPrimaryNumber={patientInfo?.phone_number}
                    patientSecondaryNumber={patientInfo?.mobile_number}
                    patientAddress={patientInfo?.address}
                  />
                )}
                {fetchingNotes && (
                  <InlineWaiting reason="Fetching patient notes..." />
                )}
                <div style={{ marginTop: 15 }}>
                  <NewNote patientId={patientId} />
                </div>
                <PatientNotes patientId={patientId} />
              </div>
            </BonafidePatientContext.Provider>
          </div>
        </PatientProfileProvider>
      </>
    </AppPermissionProvider>
  );
};

export default compose(
  withModal,
  connect(
    (state, { patientId }) => ({
      user: selectors.getUser(state),
      patientInfo: selectors.getPatientInfo(state, patientId),
      fetchingInfo: state.getIn([
        "fetch",
        fetchPatientProfileInfo.baseType,
        patientId,
        "isFetching"
      ]),
      infoLastUpdated: state.getIn([
        "fetch",
        fetchPatientProfileInfo.baseType,
        patientId,
        "lastUpdated"
      ]),
      fetchingNotes: state.getIn([
        "fetch",
        fetchPatientNotes.baseType,
        patientId,
        "isFetching"
      ]),
      patientVerification: selectors.getPatientVerification(state)
    }),
    {
      fetchPatientProfileInfo,
      fetchPatient,
      invalidatePatient,
      invalidatePatientProfileInfo
    }
  )
)(PatientProfile);

PatientProfile.propTypes = {
  patientId: PropTypes.string.isRequired,
  // postingAccess: PropTypes.bool,
  fetchWaitDuration: PropTypes.number.isRequired /* milliseconds */,
  fetchPatientProfileInfo: PropTypes.func.isRequired,
  invalidatePatientProfileInfo: PropTypes.func.isRequired,
  fetchPatient: PropTypes.func.isRequired,
  invalidatePatient: PropTypes.func.isRequired,
  infoLastUpdated: PropTypes.number,
  openModal: PropTypes.func.isRequired,
  patientInfo: PropTypes.object,
  fetchingInfo: PropTypes.bool,
  fetchingNotes: PropTypes.bool,
  user: PropTypes.object,
  verify: PropTypes.bool.isRequired,
  patientVerification: PropTypes.object.isRequired
};

PatientProfile.defaultProps = {
  fetchWaitDuration: 0 /* ms */,
  // postingAccess: false,
  verify: false
};
