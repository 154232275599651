import React from "react";
import PropTypes from "prop-types";
import { InlineWaiting } from "components/ui";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";
import { groupBy } from "ramda";

const validateNumber = value => {
  let errorMessage;
  if (value < 0) errorMessage = "the field must be greater than zero";
  return errorMessage;
};

const AddInventoryItemForm = ({
  showQuantity,
  inventory_items,
  addInventoryItem,
  patientCompatibleItems,
  disabled
}) => {
  const [usePatientCompatibleItems, setUsePatientCompatibleItems] =
    React.useState(false);

  const inventoryItemOptions = usePatientCompatibleItems
    ? inventory_items.filter(x =>
        patientCompatibleItems.some(y => y.item_id == x.invItem_ID)
      )
    : inventory_items;

  const productTypeOptions = Object.keys(
    groupBy(x => x.type)(inventoryItemOptions)
  );

  const manufacturerOptions = Object.keys(
    groupBy(x => x.manufacturer, inventoryItemOptions)
  );

  return (
    <Formik
      initialValues={{
        id: "",
        type: "",
        manufacturer: "",
        addQuantity: ""
      }}
      validate={values => {
        let errors = {};
        if (!values.id) {
          errors.id = "Required";
        }
        if (showQuantity && !values.addQuantity) {
          errors.addQuantity = "Required";
        }
        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        if (values.addQuantity > 9) {
          if (
            window.confirm(
              `Are you sure you want to set quantity to ${values.addQuantity} for this item?`
            )
          ) {
            await addInventoryItem(values);
            resetForm();
          }
        } else {
          await addInventoryItem(values);
          resetForm();
        }
      }}
    >
      {({ values, handleChange, setFieldValue, isValid, isSubmitting }) => (
        <Form>
          <div className="add-equipment-form">
            <label className="compatible-equip-checkbox">
              <span>Compatible With Patients Current Equipment</span>
              <input
                type="checkbox"
                checked={usePatientCompatibleItems}
                onChange={e => {
                  setUsePatientCompatibleItems(e.target.checked);
                  setFieldValue("id", "");
                }}
              />
            </label>
            <div className="filter-selections">
              <FormikField
                name="type"
                label="Equipment Type"
                component="select"
                onChange={e => {
                  handleChange(e);
                  //setFieldValue("manufacturer", "");
                  setFieldValue("id", "");
                }}
              >
                <option />
                {productTypeOptions.map(text => (
                  <option key={text} value={text}>
                    {text}
                  </option>
                ))}
              </FormikField>
              <FormikField
                name="manufacturer"
                label="Manufacturer"
                component="select"
                onChange={e => {
                  handleChange(e);
                  // setFilter("manufacturer_id", e.target.value);
                  setFieldValue("id", "");
                }}
              >
                <option />
                {manufacturerOptions.map(text => (
                  <option key={text} value={text}>
                    {text}
                  </option>
                ))}
              </FormikField>
            </div>
            <div className="product-select-row">
              <ProductSelect
                inventory_items={inventoryItemOptions}
                values={values}
              />
              {showQuantity && (
                <FormikField
                  name="addQuantity"
                  label="Quantity *"
                  type="number"
                  validate={validateNumber}
                />
              )}
              {isSubmitting ? (
                <InlineWaiting />
              ) : (
                <button
                  className="submit-button"
                  type="submit"
                  disabled={disabled || !isValid}
                >
                  Add Equipment
                </button>
              )}
            </div>
            {/*validateComponent && validateComponent({ ...values })*/}
          </div>
        </Form>
      )}
    </Formik>
  );
};

AddInventoryItemForm.propTypes = {
  showQuantity: PropTypes.bool,
  patientCompatibleItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  inventory_items: PropTypes.arrayOf(PropTypes.object).isRequired,
  addInventoryItem: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default AddInventoryItemForm;

const ProductSelect = ({ inventory_items, values }) => {
  var filteredProducts = inventory_items.filter(
    item =>
      (values.type.length == 0 || item.type === values.type) &&
      (values.manufacturer.length == 0 ||
        item.manufacturer === values.manufacturer)
  );
  return (
    <FormikField
      name="id"
      label="Product *"
      component="select"
      disabled={filteredProducts.length == 0}
    >
      <option />
      {filteredProducts.map(item => (
        <option key={item.invItem_ID} value={item.invItem_ID}>
          {item.description}
        </option>
      ))}
    </FormikField>
  );
};

ProductSelect.propTypes = {
  inventory_items: PropTypes.arrayOf(PropTypes.object).isRequired,
  values: PropTypes.object.isRequired
};
