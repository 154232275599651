import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { clone, equals, path } from "ramda";
import { selectors } from "reducers";
// import { clearCompanyTable } from "actions/companiesTable";
import useWindowResize from "hooks/useWindowResize";
import {
  getCompanyContactLogic,
  copyLastCompanContactLogicToNextIndex,
  clearContactLogicAtIndex,
  updateContactLogicCycles,
  clearAllContactLogic
} from "actions/company-contact-logic";
import ContactLogicTable from "./contact-logic-table";
import { useParams } from "react-router-dom";
// import { browserHistory } from "browser-history";
import { useFetch } from "hooks";
import { get } from "utils/api";

const ContactLogicTableManager = ({
  getCompanyContactLogic,
  copyLastCompanContactLogicToNextIndex,
  clearContactLogicAtIndex,
  updateContactLogicCycles,
  companies,
  formOfContactOptions,
  contactLogicArray
  // clearAllContactLogic,
  // clearCompanyTable
}) => {
  const [selectedFormOfContactOptions, setSelectedFormOfContactOptions] =
    useState([]);
  const [showAddTableButton, setShowAddTableButton] = useState(true);
  const [filteredContactLogic, setfilteredContactLogic] = useState([]);
  const [cycleChangeQueue, setCycleChangeQueue] = useState({});
  const [isUsedByForms, setIsUsedByForms] = useState(false);
  const [companyId, setCompanyId] = useState();
  const params = useParams();

  const [selectedProductLine, setSelectedProductLine] = React.useState("CPAP");

  const { fetchData: fetchContactLogicIsUsedByForms } = useFetch({
    apiFn: () => get(`contactlogic/company/${companyId}/has-forms/compliance`),
    defaultValue: {},
    transformError: path(["response", "body", "status"])
  });

  const getContactLogicValidations = async () => {
    const response = await fetchContactLogicIsUsedByForms();

    if (response && response.success)
      setIsUsedByForms(response.CompanyContactLogicHasForms);
  };

  const getPatientTypeIdentifier = patientType => {
    let identifier = 0;

    switch (patientType) {
      case "resupply":
        identifier = 1;
        break;
      case "compliance":
        identifier = 2;
        break;
      case "remote-setup":
        identifier = 3;
        break;
      default:
        console.debug("Invalid patient type");
        break;
    }

    return identifier;
  };
  const patientTypeIdentifier = getPatientTypeIdentifier(params.patientType);

  useEffect(() => {
    if (companyId) getContactLogicValidations();
  }, [companyId]);

  useEffect(() => {
    // set initial selected form of contact
    if (formOfContactOptions) {
      const callId =
        formOfContactOptions.find(x => x.text === "Manual Call")?.key ?? "";
      setSelectedFormOfContactOptions([callId]);
    }
  }, [formOfContactOptions]);

  useEffect(() => {
    setfilteredContactLogic(getContactLogicArrayFiltered());
  }, [selectedFormOfContactOptions, contactLogicArray, cycleChangeQueue]);

  const tableManagerRef = useRef(null);
  useWindowResize(() => checkAvailableSpace());
  useEffect(() => checkAvailableSpace(), [tableManagerRef, contactLogicArray]);
  const checkAvailableSpace = () => {
    if (tableManagerRef !== null) {
      const numColumns = contactLogicArray.length + 1;
      const columnWidth = 300;
      const columnGap = 25;
      const spaceConsumed = numColumns * columnWidth + numColumns * columnGap;
      setShowAddTableButton(
        spaceConsumed < tableManagerRef.current.clientWidth &&
          patientTypeIdentifier == 2
      );
    }
  };

  const getContactLogicArrayFiltered = () => {
    const mutableContactLogicArray = clone(contactLogicArray);

    mutableContactLogicArray.forEach((element, index) => {
      element.cycles = element.cycles.filter(
        cycle =>
          !selectedFormOfContactOptions[index] ||
          cycle.actionId === selectedFormOfContactOptions[index]
      );

      const cycleChanges = cycleChangeQueue[element.companyId];

      cycleChanges?.forEach(cycleChange => {
        if (cycleChange.shouldUpdate) {
          const index = element.cycles.findIndex(
            cycle => cycle.id === cycleChange.id
          );
          element.cycles[index] = cycleChange;
        }

        if (cycleChange.shouldCreate) element.cycles.push(cycleChange);

        if (cycleChange.shouldDelete)
          element.cycles = element.cycles.filter(
            cycle => cycle.id !== cycleChange.id
          );
      });

      element.cycles.sort((a, b) => a.cycleCount - b.cycleCount);
    });

    return mutableContactLogicArray;
  };

  const updateTableData = (index, companyId, patientTypeId) => {
    const contactLogic = contactLogicArray[index];

    if ((companyId && !contactLogic) || contactLogic.company_id !== companyId) {
      setCompanyId(companyId);
      getCompanyContactLogic(companyId, index, patientTypeId);
    }
  };

  const updateSelectedFormOfContactOptions = (value, index) => {
    let temp = clone(selectedFormOfContactOptions);
    temp[index] = value;
    setSelectedFormOfContactOptions(temp);
  };

  const addNewTable = () => {
    const newItem =
      selectedFormOfContactOptions[selectedFormOfContactOptions.length - 1];
    setSelectedFormOfContactOptions([...selectedFormOfContactOptions, newItem]);
    copyLastCompanContactLogicToNextIndex();
  };

  // const clearAll = async () => {
  //   clearCompanyTable();
  //   clearAllContactLogic();
  // };

  const closeTable = index => {
    clearContactLogicAtIndex(index);
    setCycleChangeQueue(cycleChangeQueue.slice(index, 1));
  };

  const addCycleChangeToQueue = (companyId, cycle) => {
    if (cycle.shouldUpdate) addExistingCycleChangeToQueue(companyId, cycle);

    if (cycle.shouldCreate) addNewCycleChangeToQueue(companyId, cycle);
  };

  const addExistingCycleChangeToQueue = (companyId, cycle) => {
    const originalCycle = contactLogicArray
      .find(x => x.companyId === companyId)
      .cycles.find(x => x.id === cycle.id);

    let mutableCycleChangeQueue = clone(cycleChangeQueue);

    if (!mutableCycleChangeQueue[companyId])
      mutableCycleChangeQueue[companyId] = [];

    mutableCycleChangeQueue[companyId] = mutableCycleChangeQueue[
      companyId
    ].filter(x => x.id !== cycle.id);

    if (!equals(cycle, originalCycle))
      mutableCycleChangeQueue[companyId].push(cycle);

    setCycleChangeQueue(mutableCycleChangeQueue);
  };

  const addNewCycleChangeToQueue = (companyId, cycle) => {
    let mutablecycleChangeQueue = clone(cycleChangeQueue);

    mutablecycleChangeQueue[companyId] = mutablecycleChangeQueue[
      companyId
    ].filter(x => x.id !== cycle.id);

    mutablecycleChangeQueue[companyId].push(cycle);

    setCycleChangeQueue(mutablecycleChangeQueue);
  };

  const addNewCycleToQueue = (companyId, cycle) => {
    let mutableCycleChangeQueue = clone(cycleChangeQueue);

    if (!mutableCycleChangeQueue[companyId])
      mutableCycleChangeQueue[companyId] = [];

    mutableCycleChangeQueue[companyId].push(cycle);

    setCycleChangeQueue(mutableCycleChangeQueue);
  };

  const addDeleteCycleToQueue = (companyId, cycle) => {
    let mutableCycleChangeQueue = clone(cycleChangeQueue);

    if (!mutableCycleChangeQueue[companyId])
      mutableCycleChangeQueue[companyId] = [];

    mutableCycleChangeQueue[companyId] = mutableCycleChangeQueue[
      companyId
    ].filter(x => x.id !== cycle.id);

    if (!cycle.shouldCreate) mutableCycleChangeQueue[companyId].push(cycle);

    setCycleChangeQueue(mutableCycleChangeQueue);
  };

  const saveContactLogicUpdates = (index, companyId) => {
    if (cycleChangeQueue[companyId]?.length) {
      updateContactLogicCycles(companyId, cycleChangeQueue[companyId]);
      delete cycleChangeQueue[companyId];
    }
  };

  return (
    <div className="contact-logic-table-manager" ref={tableManagerRef}>
      <div className="contact-logic-container">
        {filteredContactLogic.map((_, index, array) => (
          <ContactLogicTable
            key={index}
            companyOptions={companies.filter(x =>
              x.patient_stages?.some(y =>
                y.toLowerCase().includes(params.patientType)
              )
            )}
            onCompanySelect={companyId =>
              updateTableData(index, companyId, patientTypeIdentifier)
            }
            formOfContactOptions={formOfContactOptions}
            selectedFormOfContact={selectedFormOfContactOptions[index] || ""}
            onFormOfContactSelect={value =>
              updateSelectedFormOfContactOptions(value, index)
            }
            contactLogicArray={array}
            index={index}
            onCycleChanged={(companyId, cycle) =>
              addCycleChangeToQueue(companyId, cycle)
            }
            onCycleAdded={(companyId, cycle) =>
              addNewCycleToQueue(companyId, cycle)
            }
            onCycleDelete={(companyId, cycle) =>
              addDeleteCycleToQueue(companyId, cycle)
            }
            onClose={() => closeTable(index)}
            onSave={companyId => saveContactLogicUpdates(index, companyId)}
            patientTypeId={patientTypeIdentifier}
            companyHasForms={isUsedByForms}
          />
        ))}
        <div>
          <span>
            <label
              style={{
                alignItems: "center",
                cursor: "pointer",
                justifyContent: "center",
                fontSize: "1.15em",
                display: "inline-flex",
                paddingRight: 20
              }}
            >
              CPAP
              <input
                type="radio"
                style={{ margin: "0px 10px" }}
                checked={selectedProductLine == "CPAP"}
                onClick={() => {
                  setSelectedProductLine("CPAP");
                }}
              />
            </label>
          </span>
          <span>
            <label
              style={{
                alignItems: "center",
                cursor: "pointer",
                justifyContent: "center",
                fontSize: "1.15em",
                display: "inline-flex",
                paddingRight: 20
              }}
            >
              CGM
              <input
                type="radio"
                style={{ margin: "0px 10px" }}
                checked={selectedProductLine == "CGM"}
                onClick={() => {
                  setSelectedProductLine("CGM");
                }}
              />
            </label>
          </span>
        </div>
        {showAddTableButton && (
          <div className="open-new-table-button-container">
            <div
              className="open-new-table-button"
              onClick={() => addNewTable()}
            >
              <div className="plus-symbol" />
              <div className="plus-symbol" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ContactLogicTableManager.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  formOfContactOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  contactLogicArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  getCompanyContactLogic: PropTypes.func.isRequired,
  copyLastCompanContactLogicToNextIndex: PropTypes.func.isRequired,
  clearContactLogicAtIndex: PropTypes.func.isRequired,
  updateContactLogicCycles: PropTypes.func.isRequired,
  clearAllContactLogic: PropTypes.func.isRequired
  // clearCompanyTable: PropTypes.func.isRequired
};

export default connect(
  state => ({
    companies: selectors.getFormOptionsCompany(state),
    formOfContactOptions: selectors.getFormOfContactOptions(state),
    contactLogicArray: state.get("contactLogic")
  }),
  {
    // clearCompanyTable,
    getCompanyContactLogic,
    copyLastCompanContactLogicToNextIndex,
    clearContactLogicAtIndex,
    updateContactLogicCycles,
    clearAllContactLogic
  }
)(ContactLogicTableManager);
