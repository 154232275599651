import React, { useContext, useState } from "react";
import HcpcTypeForm from "./hcpc-eligibility-form";
import { EligibilityGroup } from "./hcpc-eligibility-group";
import { Waiting } from "components/ui";
import PropTypes from "prop-types";
import { InsuranceCompanyContext } from "./insurance-company-provider";
import HasRolePermission from "components/shared/has-role-permission";

export const HcpcEligibilityTableRow = ({
  handleSubmit,
  editableFields,
  ToggleEditability
}) => {
  const compareType = (a, b) => {
    const val1 = a.hcpc_type.toUpperCase();
    const val2 = b.hcpc_type.toUpperCase();
    let comparison = 0;
    if (val1 > val2) {
      comparison = 1;
    } else if (val1 < val2) {
      comparison = -1;
    }
    return comparison;
  };
  const compareHCPC = (a, b) => {
    const val1 = a.hcpc.toUpperCase();
    const val2 = b.hcpc.toUpperCase();
    let comparison = 0;
    if (val1 > val2) {
      comparison = 1;
    } else if (val1 < val2) {
      comparison = -1;
    }
    return comparison;
  };
  const compareData = (a, b) => {
    const val1 = a.days_to_push_eligibility;
    const val2 = b.days_to_push_eligibility;
    let comparison = 0;
    if (val1 > val2) {
      comparison = 1;
    } else if (val1 < val2) {
      comparison = -1;
    }
    return comparison;
  };
  const { hcpcs, isFetchingHcpcs } = useContext(InsuranceCompanyContext);
  const [selectedProductLine, setSelectedProductLine] = useState("CPAP");
  return isFetchingHcpcs ? (
    <div className="table-loading-spinner">
      <Waiting size="50px" style={{ position: "sticky" }} />
    </div>
  ) : (
    <div>
      <div
        style={{
          padding: 10,
          display: "grid",
          gridTemplateColumns: "80px 80px",
          gridGap: 10
        }}
      >
        <div style={{}}>
          <input
            type="radio"
            id="cpap_option"
            value="CPAP"
            style={{ margin: 0 }}
            checked={selectedProductLine == "CPAP"}
            onChange={() => setSelectedProductLine("CPAP")}
          />
          <label htmlFor="cpap_option" style={{}}>
            CPAP
          </label>
        </div>
        <div style={{}}>
          <input
            type="radio"
            id="cgpm_option"
            value="CGM"
            style={{ margin: 0 }}
            checked={selectedProductLine == "CGM"}
            onChange={() => setSelectedProductLine("CGM")}
          />
          <label htmlFor="cgm_option" style={{}}>
            CGM
          </label>
        </div>
      </div>
      {hcpcs.hcpcEligibility
        ?.filter(x => x.product_line == selectedProductLine)
        .sort(compareType)
        .map(hcpcGroup => {
          return (
            <div key={hcpcGroup.hcpc_type}>
              <div className="type-header">{hcpcGroup.hcpc_type}</div>
              {hcpcGroup.hcpcs.sort(compareHCPC).map((hcpc, index) => {
                return (
                  <div
                    className={
                      (index + 1) % 2 == 0 ? "hcpc-row" : "hcpc-row-gray"
                    }
                    key={hcpc.hcpc + "-" + index}
                  >
                    <div className="hcpc-row-header">
                      <div className="hcpc-header">{hcpc.hcpc}</div>
                      <HasRolePermission
                        allowedRoles={[
                          "Administrator",
                          "ServiceAdmin",
                          "CompanyAdministrator"
                          //,'ContractorAdministrator'
                        ]}
                      >
                        <div style={{ height: "30px", alignSelf: "center" }}>
                          <div>
                            {editableFields == hcpc.hcpc ? (
                              <button
                                className="edit-mode-button"
                                onClick={() => ToggleEditability(hcpc.hcpc)}
                              >
                                {" "}
                                Cancel
                              </button>
                            ) : (
                              <button
                                className="edit-mode-button"
                                onClick={() => ToggleEditability(hcpc.hcpc)}
                              >
                                Edit
                              </button>
                            )}
                          </div>
                        </div>
                      </HasRolePermission>
                    </div>
                    <div className="eligibility-container">
                      {editableFields == hcpc.hcpc ? (
                        <HcpcTypeForm
                          values={hcpc}
                          handleSubmit={handleSubmit}
                          compareData={compareData}
                        />
                      ) : (
                        <EligibilityGroup
                          data={hcpc.data}
                          compareData={compareData}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
    </div>
  );
};

HcpcEligibilityTableRow.propTypes = {
  handleSubmit: PropTypes.func,
  hcpcs: PropTypes.arrayOf(PropTypes.object),
  editableFields: PropTypes.string,
  ToggleEditability: PropTypes.func,
  isFetchingHcpcs: PropTypes.bool
};
